import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import LayoutContainer from 'components/Common/LayoutContainer/LayoutContainer'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { rem } from 'polished'
import BannerCarousel from 'components/Common/Banner/BannerCarousel'
import { fetchListBanners } from 'actions/ContentActions'
import { BANNER_LOCATION_HOME } from 'constants/bannerLocations'
import { useAppDispatch } from 'hooks/reduxHooks'
import * as Analytics from 'analytics/analytics'
import { EmptyArray, take } from 'lib/array/arrayUtils'
import { promotionClickEvent, promotionViewEvent } from 'analytics/snowplow/events'

const MarketingCarouselContainer = styled(LayoutContainer)`
  padding-top: ${rem(40)};
  padding-bottom: ${rem(40)};
`

interface Props {
  banners?: Array<App.ListBanner>;
}

function MarketingCarousel(props: Props) {
  const { banners = EmptyArray } = props
  const dispatch = useAppDispatch()

  // The wrapAround prop on nuka-carousel create "cloned" divs for infinity scroll behavior.
  // We just want to trigger load event once for each banner
  const loadedBanners = useRef(new Set<string>())

  useEffect(() => {
    dispatch(fetchListBanners(BANNER_LOCATION_HOME))
  // eslint-disable-next-line
  }, [])

  const onBannerClick = useCallback((banner: App.ListBanner) => {
    Analytics.trackEvent(promotionClickEvent(banner.bannerId))
  }, [])

  const onBannerLoad = useCallback((banner: App.ListBanner) => {
    if (loadedBanners.current.has(banner.bannerId)) return

    loadedBanners.current.add(banner.bannerId)
    Analytics.trackEvent(promotionViewEvent(banner.bannerId))
  }, [])

  const visibleBanners = useMemo(() => take(banners, 3), [banners])

  return (
    <>
      {banners.length > 0 && (
        <MarketingCarouselContainer>
          <BannerCarousel
            banners={visibleBanners}
            onBannerClick={onBannerClick}
            onBannerLoad={onBannerLoad}
            onClickOpenNewTab
          />
        </MarketingCarouselContainer>
      )}
    </>
  )
}

function mapStateToProps(state: App.State) {
  return {
    banners: state.content.listBanners.home,
  }
}

export default connect(mapStateToProps)(MarketingCarousel)
