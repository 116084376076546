export const BANNER_LOCATION_HOME = 'home'
export const BANNER_LOCATION_HUB = 'hub'
export const BANNER_LOCATION_MEMBERSHIP = 'membership'
export const BANNER_LOCATION_HOTELS_AND_RESORTS = 'hotelsAndResorts'
export const BANNER_LOCATION_TOURS_AND_CRUISES = 'toursAndCruises'
export const BANNER_LOCATION_HOMES_AND_VILLAS = 'homesAndVillas'
export const BANNER_LOCATION_LAST_MINUTE = 'lastMinute'
export const BANNER_LOCATION_EXPERIENCES = 'experiences'
export const BANNER_LOCATION_LE_TV_PAGE = 'leTVPage'
export const BANNER_LOCATION_LE_TV_EPISODE = 'leTVEpisode'
export const BANNER_LOCATION_LE_TV_COMPETITION = 'leTVCompetition'
export const BANNER_LOCATION_CRUISES = 'cruises'
