import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineWhatsappIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <g>
      <path fill="currentColor" d="M17.4984 14.3811c-.2978-.1488-1.7665-.869-2.0402-.9674-.2737-.0996-.4727-.1488-.6716.1488-.199.2965-.7717.9674-.9466 1.1655-.1736.198-.3484.222-.6463.0732a8.1649 8.1649 0 0 1-2.4007-1.4739 8.9595 8.9595 0 0 1-1.6616-2.0597c-.1736-.2976-.018-.4585.1315-.6061.1338-.1332.299-.3469.4473-.5209a2.035 2.035 0 0 0 .299-.4957.5438.5438 0 0 0-.0253-.521c-.0747-.1488-.6716-1.6119-.92-2.2072-.2424-.5785-.4883-.5005-.6728-.5101-.1737-.0084-.3726-.0108-.5716-.0108a1.0984 1.0984 0 0 0-.7958.3732 3.3325 3.3325 0 0 0-.7849 1.1297 3.3193 3.3193 0 0 0-.2605 1.3489 5.7635 5.7635 0 0 0 1.219 3.0751 13.2862 13.2862 0 0 0 5.1005 4.4877 17.6223 17.6223 0 0 0 1.7026.6266 4.1056 4.1056 0 0 0 1.881.1164 3.0726 3.0726 0 0 0 1.1561-.4955 3.058 3.058 0 0 0 .8588-.916 2.4629 2.4629 0 0 0 .1736-1.4139c-.0735-.1248-.2725-.1981-.5715-.3469Zm3.0048-10.8972C18.4533 1.444 15.7285.215 12.8361.0257c-2.8924-.1891-5.7553.6744-8.0558 2.43C2.48 4.211.8987 6.739.3313 9.5684a11.851 11.851 0 0 0 1.364 8.2682L0 24l6.3352-1.6528a11.9866 11.9866 0 0 0 5.7106 1.4476h.0048a11.9947 11.9947 0 0 0 6.6384-2.0072c1.9648-1.3075 3.4963-3.1654 4.4008-5.3389a11.8503 11.8503 0 0 0 .6812-6.8727 11.8837 11.8837 0 0 0-3.2678-6.092Zm-3.1748 16.7905a9.9768 9.9768 0 0 1-5.2778 1.5111h-.0048a9.9498 9.9498 0 0 1-5.0546-1.3791l-.363-.2136-3.7596.9818 1.0032-3.6488-.2351-.3745a9.852 9.852 0 0 1-1.5134-5.5384 9.8565 9.8565 0 0 1 1.8328-5.442 9.926 9.926 0 0 1 4.5612-3.5113 9.9751 9.9751 0 0 1 5.7522-.3972 9.943 9.943 0 0 1 5.0045 2.8508 9.869 9.869 0 0 1 2.5701 5.138 9.845 9.845 0 0 1-.7307 5.6933 9.9004 9.9004 0 0 1-3.785 4.3299Z"/>
    </g>
    <defs>
      <clipPath id="a">
        <path fill="currentColor" d="M0 0H24V24H0z"/>
      </clipPath>
    </defs>
  </SvgIcon>
}

export default LineWhatsappIcon
