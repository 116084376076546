import React, { useContext, useMemo } from 'react'
import { rem } from 'polished'
import styled from 'styled-components'
import Pane from 'components/Common/Pane'
import { getValueSectionContent } from './constants'
import LayoutContainer from 'components/Common/LayoutContainer'
import Heading from 'components/Luxkit/Typography/Heading'
import GeoContext from 'contexts/geoContext'
import ValuePropositionItems from './ValuePropositionItems'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'

const StyledLayoutContainer = styled(LayoutContainer)`
  padding-top: ${rem(40)};
  padding-bottom: ${rem(40)};
`

function ValuePropositionBanner() {
  const { currentRegionCode } = useContext(GeoContext)
  const values = useMemo(() => getValueSectionContent(currentRegionCode).filter(value => value.show), [currentRegionCode])

  return (
    <Pane>
      <StyledLayoutContainer>
        <VerticalSpacer gap={40}>
          <Heading variant="heading2">Why you should book with us</Heading>
          <ValuePropositionItems values={values} />
        </VerticalSpacer>
      </StyledLayoutContainer>
    </Pane>
  ) }

export default React.memo(ValuePropositionBanner)
