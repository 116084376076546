import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineListUlIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M3.71 16.29a1.0007 1.0007 0 0 0-.33-.21c-.2435-.1-.5166-.1-.76 0a1.0007 1.0007 0 0 0-.33.21.9994.9994 0 0 0-.21.33 1 1 0 0 0 .21 1.09c.0972.0883.2088.1594.33.21a.94.94 0 0 0 .76 0 1.1498 1.1498 0 0 0 .33-.21 1 1 0 0 0 .21-1.09.9996.9996 0 0 0-.21-.33ZM7 8h14a1 1 0 1 0 0-2H7a1 1 0 0 0 0 2Zm-3.29 3.29a.9996.9996 0 0 0-1.09-.21 1.1497 1.1497 0 0 0-.33.21.9994.9994 0 0 0-.21.33.9398.9398 0 0 0 0 .76c.0506.1212.1217.2328.21.33.0972.0883.2088.1594.33.21a.94.94 0 0 0 .76 0 1.1498 1.1498 0 0 0 .33-.21 1.1498 1.1498 0 0 0 .21-.33.94.94 0 0 0 0-.76.9996.9996 0 0 0-.21-.33ZM21 11H7a1.0002 1.0002 0 0 0-.7071 1.7071 1 1 0 0 0 .707.2929H21a1.0001 1.0001 0 0 0 0-2ZM3.71 6.29a1 1 0 0 0-.33-.21 1 1 0 0 0-1.09.21 1.15 1.15 0 0 0-.21.33.94.94 0 0 0 0 .76c.0506.1212.1217.2328.21.33.0972.0883.2088.1594.33.21a1 1 0 0 0 1.09-.21 1.1499 1.1499 0 0 0 .21-.33.94.94 0 0 0 0-.76 1.15 1.15 0 0 0-.21-.33ZM21 16H7a1.0002 1.0002 0 0 0-.7071 1.7071 1 1 0 0 0 .707.2929H21a1.0001 1.0001 0 0 0 0-2Z"/>
  </SvgIcon>
}

export default LineListUlIcon
