import React from 'react'

import PriceDetails from 'tripPlanner/components/ItemOfferPrice/PriceDetails'

import BodyText from 'components/Luxkit/Typography/BodyText'
import { MINIMUM_DISCOUNT_TO_SHOW_PERCENTAGE_BADGE } from 'constants/content'

interface Props {
  /** If null, don't show price */
  price?: number
  luxPlusPrice?: number
  currencyCode: string
  priceLabel?: string
  isOfferAvailable: boolean
  discountPercent?: number
  discountDisplayType?: React.ComponentProps<
    typeof PriceDetails
  >['discountDisplayType']
  totalLabel?: string
}

export function PlannedItemPriceContent(props: Props) {
  const {
    price,
    luxPlusPrice,
    priceLabel = 'From',
    discountPercent,
    currencyCode,
    isOfferAvailable,
    discountDisplayType,
    totalLabel,
  } = props

  const showPrice = typeof price === 'number'

  const shouldShowDiscountPercent =
    !!discountPercent &&
    discountPercent >= MINIMUM_DISCOUNT_TO_SHOW_PERCENTAGE_BADGE

  if (!isOfferAvailable) {
    return (
      <BodyText variant="large" weight="bold">
        Sold Out
      </BodyText>
    )
  }

  if (!showPrice) {
    return null
  }

  return (
    <PriceDetails
      price={Math.ceil(price)}
      luxPlusPrice={luxPlusPrice ? Math.ceil(luxPlusPrice) : undefined}
      currencyCode={currencyCode}
      discount={
        shouldShowDiscountPercent ? Math.floor(discountPercent) : undefined
      }
      priceLabel={priceLabel}
      discountDisplayType={discountDisplayType ?? 'badge'}
      totalLabel={totalLabel}
    />
  )
}
