import React, { useCallback, useContext, useMemo } from 'react'

import LoadingItemCard from './LoadingItemCard'
import BookmarkLocation from '../Bookmark/BookmarkCardNew/BookmarkLocation'
import { PlannedItemPriceContent } from '../ItemCard/PlannedItemPriceContent'

import OfferListEventsContext, {
  OfferListEvents,
} from 'components/OfferList/OfferListEventsContext'
import { EventDataKey } from 'home/pages/HomePage/useHomepageAnalytics'
import { nonNullable } from 'lib/array/arrayUtils'
import noop from 'lib/function/noop'
import offerPageURL from 'lib/offer/offerPageURL'
import { isVillaOffer } from 'lib/offer/offerTypes'
import MiniSavedOfferCard from './MiniSavedOfferCard'
import useOffer from 'hooks/Offers/useOffer'
import useAccommodationItemOccupancyDescription from 'tripPlanner/hooks/occupancy/useAccommodationItemOccupancyDescription'
import useAccommodationOfferAvailable from 'tripPlanner/hooks/useAccommodationOfferAvailable'
import { AccommodationItem } from 'tripPlanner/types/tripItem'
import {
  buildMyEscapesOrderLink,
  formatShortDateRange,
  getOfferImage,
  isLeBookmark,
} from 'tripPlanner/utils'
import { buildProductLink } from 'tripPlanner/utils/bookmark/restoreCart'
import { getAccommodationPriceAndLabel } from 'tripPlanner/utils/hotel'

interface Props {
  item: AccommodationItem
  onClick?: () => void
  position?: number
}

function AccommodationItemCard({ item, onClick = noop, position }: Props) {
  // The query type includes hotels and tours but this card is only used for hotels
  const [offer, isLoading] = useOffer<App.Offer | App.BedbankOffer>(item.code)

  const { id: imgId, url: imgSrc } = getOfferImage(offer)

  const isOfferAvailable = useAccommodationOfferAvailable(
    offer,
    item.savedItemData,
    item.code,
  )

  const timeDescription = formatShortDateRange(item)
  const { occupanciesText, numberOfRoomsText } =
    useAccommodationItemOccupancyDescription(item)
  const timeAndOccupancyText = nonNullable([timeDescription, occupanciesText])
    .filter((text) => text.length > 0)
    .join(', ')

  const descriptors = [timeAndOccupancyText, numberOfRoomsText]

  const priceAreaContent = useMemo(() => {
    if (
      isLeBookmark(item) &&
      offer &&
      (offer.parentType === 'hotel' || isVillaOffer(offer))
    ) {
      const { discountPercent, currencyCode } = item.savedItemData

      const { price, luxPlusPrice, priceLabel } = getAccommodationPriceAndLabel(
        offer,
        item.savedItemData,
      )

      return (
        <PlannedItemPriceContent
          discountPercent={discountPercent}
          price={price}
          luxPlusPrice={luxPlusPrice}
          priceLabel={priceLabel}
          currencyCode={currencyCode}
          isOfferAvailable={isOfferAvailable}
          discountDisplayType="inlineBadge"
          totalLabel=""
        />
      )
    }
    return null
  }, [isOfferAvailable, item, offer])

  const locationDescription = useMemo(
    () => (
      <BookmarkLocation
        variant="single"
        startLocation={item.place.region}
        showIcon={false}
        size="medium"
      />
    ),
    [item.place.region],
  )

  const dispatchOfferListEvent = useContext(OfferListEventsContext)

  const onImpression = useCallback(() => {
    if (offer && position !== undefined) {
      dispatchOfferListEvent({
        type: OfferListEvents.productImpression,
        offer,
        position,
        key: EventDataKey.ProductImpression,
      })
    }
  }, [dispatchOfferListEvent, offer, position])

  const onClickCard = useCallback(() => {
    onClick()
    if (offer && position !== undefined) {
      dispatchOfferListEvent({
        type: OfferListEvents.productClick,
        offer,
        position,
        key: EventDataKey.ProductClick,
      })
    }
  }, [onClick, dispatchOfferListEvent, offer, position])

  if (isLoading)
  { return (
    <LoadingItemCard
        itemType={item.type}
        includeDescriptors={descriptors.length > 0}
      />
  ) }
  if (!offer) return null

  let rating: App.OfferRating | undefined
  if ('property' in offer && offer.property) {
    rating = offer.property.rating
  }

  let tileLink: string | undefined
  if (
    (item.sourceType === 'LE_Hotel' ||
      item.sourceType === 'LE_Hotel_Bedbank') &&
    item.isBooked &&
    item.confirmationCode
  ) {
    tileLink = buildMyEscapesOrderLink(item)
  } else if (isLeBookmark(item)) {
    tileLink = buildProductLink(item, true, { offer })
  } else {
    tileLink = offerPageURL(offer)
  }

  return (
    <MiniSavedOfferCard
      to={tileLink}
      onClick={onClickCard}
      onImpression={onImpression}
      item={item}
      name={item.place.name}
      imgId={imgId}
      imgSrc={imgSrc}
      locationDescription={locationDescription}
      descriptors={descriptors}
      priceAreaContent={priceAreaContent}
      isSoldOut={!isOfferAvailable}
      rating={rating}
    />
  )
}

export default AccommodationItemCard
