import { rem } from 'polished'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import Clickable from 'components/Common/Clickable'
import Image from 'components/Common/Image'
import LineCalendarIcon from 'components/Luxkit/Icons/line/LineCalendarIcon'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import BodyText from 'components/Luxkit/Typography/BodyText'
import { DEFAULT_TRIP_IMAGE } from 'tripPlanner/config'
import { BasicTrip } from 'tripPlanner/types/common'
import { formatCasualDateRange } from 'tripPlanner/utils'
import LineMapMarkerIcon from 'components/Luxkit/Icons/line/LineMapMarkerIcon'
import { getDestinationsLabel } from 'tripPlanner/utils/places'
import LineHeartIcon from 'components/Luxkit/Icons/line/LineHeartIcon'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'

const Panel = styled(Clickable)`
  display: grid;
  width: 100%;
  grid-template: 1fr / ${rem(80)} 1fr;
  align-items: center;
  padding: ${rem(12)};
  gap: ${rem(16)};
  border-radius: ${(props) => props.theme.borderRadius.S};
  transition: background-color 0.2s;
  background-color: ${(props) => props.theme.palette.neutral.default.eight};

  &:hover {
    background-color: ${props => props.theme.palette.neutral.default.seven};
  }
`

const RoundedImage = styled(Image)`
  border-radius: ${(props) => props.theme.borderRadius.S};
`

interface Props {
  trip: BasicTrip
  onSelectTrip: (tripId: string) => void
}

function TripPanel({ trip, onSelectTrip }: Props) {
  const image = useMemo(
    (): App.Image => ({
      id: trip.imageId,
      url: trip.imageUrl || DEFAULT_TRIP_IMAGE,
    }),
    [trip],
  )

  const destinationsGeo = trip.destinationsGeo
  const hasDestination = destinationsGeo && destinationsGeo.length > 0
  const destinationsText = hasDestination ?
    getDestinationsLabel(destinationsGeo, 1) :
    'No destinations set'
  const numberOfSavedItems = trip.itemCount
  const hasDates = !!(trip.startDate && trip.endDate)

  return (
    <Panel onClick={() => onSelectTrip(trip.id)}>
      <RoundedImage height={80} width={80} image={image} fit="center" />
      <VerticalSpacer gap={2}>
        <BodyText variant="large">{trip.name}</BodyText>
        <BodyTextBlock
          variant="small"
          colour={hasDestination ? 'neutral-one' : 'neutral-three'}
          startIcon={<LineMapMarkerIcon size="XS" />}
        >
          {destinationsText}
        </BodyTextBlock>
        <BodyTextBlock
          variant="small"
          colour={hasDates ? 'neutral-one' : 'neutral-three'}
          startIcon={<LineCalendarIcon size="XS" />}
        >
          {hasDates && formatCasualDateRange(trip.startDate!, trip.endDate)}
          {!hasDates && 'No dates added'}
        </BodyTextBlock>
        <BodyTextBlock
          variant="small"
          startIcon={<LineHeartIcon size="XS" />}
        >
          {numberOfSavedItems}
        </BodyTextBlock>
      </VerticalSpacer>
    </Panel>
  )
}

export default TripPanel
