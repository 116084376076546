import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineEllipsisVIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M12 7a1.9998 1.9998 0 0 0 1.9616-2.3902 2.0003 2.0003 0 0 0-2.727-1.4576A1.9998 1.9998 0 0 0 10 5a2 2 0 0 0 2 2Zm0 10a2 2 0 0 0-1.9616 2.3902 2.0004 2.0004 0 0 0 1.5714 1.5714A2 2 0 1 0 12 17Zm0-7a2 2 0 0 0-1.9616 2.3902 2.0004 2.0004 0 0 0 1.5714 1.5714A2 2 0 1 0 12 10Z"/>
  </SvgIcon>
}

export default LineEllipsisVIcon
