import LayoutContainer from 'components/Common/LayoutContainer'
import Group from 'components/utils/Group'
import React from 'react'
import LoadingItemCard from 'tripPlanner/components/MiniOfferItemCard/LoadingItemCard'

function TripOfferCarouselSkeleton() {
  return (
    <LayoutContainer>
      <Group direction="horizontal" gap={16} wrap="nowrap">
        <LoadingItemCard includeDescriptors />
        <LoadingItemCard includeDescriptors />
        <LoadingItemCard includeDescriptors />
        <LoadingItemCard includeDescriptors />
      </Group>
    </LayoutContainer>
  )
}

export default TripOfferCarouselSkeleton
