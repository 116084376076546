import React, { useCallback, useContext, useMemo } from 'react'

import OfferListEventsContext, {
  OfferListEvents,
} from 'components/OfferList/OfferListEventsContext'
import { EventDataKey } from 'home/pages/HomePage/useHomepageAnalytics'
import { useRegionTimeFormat } from 'hooks/useRegionTimeFormat'
import noop from 'lib/function/noop'
import offerPageURL from 'lib/offer/offerPageURL'
import { isLEOffer } from 'lib/offer/offerTypes'
import { scheduleIsCurrent } from 'lib/offer/scheduleStatusUtils'
import BookmarkLocation from 'tripPlanner/components/Bookmark/BookmarkCardNew/BookmarkLocation'
import LEOfferPriceDetails from 'tripPlanner/components/ItemOfferPrice/LEOfferPriceDetails'
import MiniSavedOfferCard from '../MiniSavedOfferCard'
import { TourItem } from 'tripPlanner/types/tripItem'
import {
  buildMyEscapesOrderLink,
  formatDatesAndDays,
  formatDurationDaysIfMultipleDays,
  isLeBookmark,
} from 'tripPlanner/utils'
import { buildProductLink } from 'tripPlanner/utils/bookmark/restoreCart'

interface Props {
  item: TourItem
  offer: App.TourOffer
  onClick?: () => void
  position?: number
}

export default function TourV1ItemCard({
  item,
  offer,
  onClick = noop,
  position,
}: Props) {
  const timeFormat = useRegionTimeFormat()

  const timeDescription: string | undefined = formatDatesAndDays(
    item,
    timeFormat,
  )

  const isOfferAvailable = isLEOffer(offer) ?
    scheduleIsCurrent(offer.onlinePurchaseSchedule) :
    true

  const durationDescription = formatDurationDaysIfMultipleDays(item)

  const priceAreaContent = useMemo(() => {
    if (isLeBookmark(item)) {
      return (
        <LEOfferPriceDetails
          offer={offer}
          package={offer.packages.find(
            (p) => p.id === item.savedItemData.packageId,
          )}
        />
      )
    }
    return null
  }, [item, offer])

  const locationDescription = useMemo(
    () => (
      <BookmarkLocation
        variant="single"
        startLocation={item.startPlace.name}
        showIcon={false}
      />
    ),
    [item.startPlace.name],
  )

  let tileLink: string | undefined
  if (item.sourceType === 'LE_Tour' && item.isBooked && item.confirmationCode) {
    tileLink = buildMyEscapesOrderLink(item)
  } else if (isLeBookmark(item)) {
    tileLink = buildProductLink(item, true, { offer })
  } else {
    tileLink = offerPageURL(offer)
  }

  const dispatchOfferListEvent = useContext(OfferListEventsContext)

  const onImpression = useCallback(() => {
    if (position !== undefined) {
      dispatchOfferListEvent({
        type: OfferListEvents.productImpression,
        offer,
        position,
        key: EventDataKey.ProductImpression,
      })
    }
  }, [dispatchOfferListEvent, offer, position])

  const onClickCard = useCallback(() => {
    onClick()
    if (position !== undefined) {
      dispatchOfferListEvent({
        type: OfferListEvents.productClick,
        offer,
        position,
        key: EventDataKey.ProductClick,
      })
    }
  }, [onClick, dispatchOfferListEvent, offer, position])

  return (
    <MiniSavedOfferCard
      to={tileLink}
      onClick={onClickCard}
      onImpression={onImpression}
      item={item}
      name={item.name}
      imgId={offer.image.id}
      locationDescription={locationDescription}
      descriptors={[timeDescription, durationDescription]}
      priceAreaContent={priceAreaContent}
      isSoldOut={!isOfferAvailable}
    />
  )
}
