import { rem } from 'polished'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import HorizontalContainerWithFadedOverflow from 'components/Common/HorizontalContainerWithFadedOverflow'
import FilterChip from 'components/Luxkit/Chips/FilterChip'
import Group from 'components/utils/Group'
import { EmptyArray } from 'lib/array/arrayUtils'
import { FilterIcons, FilterLabels } from 'tripPlanner/models'
import { TripPlannerFilterTypeCount } from 'tripPlanner/utils/summary'
import LineListUlIcon from 'components/Luxkit/Icons/line/LineListUlIcon'

const StyledGroup = styled(Group)`
  /*
    This CSS ensures the filter chip's parent accounts for their focus/active outline styles
    so that the scrollable container doesn't vertically scroll when the user interacts with the chips
  */
  padding: ${rem(4)};
  margin: 0 ${rem(-4)};
`

interface Props {
  itemCount: number
  availableSubFilters: Array<TripPlannerFilterTypeCount>
  selectedFilters: Array<App.TripPlannerSubFilterType>
  onToggleFilter: (filterType: App.TripPlannerSubFilterType) => void
  onClearFilters: () => void
}

function TripPlannerFilterChips({
  availableSubFilters = EmptyArray,
  selectedFilters,
  onToggleFilter,
  onClearFilters,
  itemCount,
}: Props) {
  const selectedFiltersSet = useMemo(
    () => new Set(selectedFilters),
    [selectedFilters],
  )

  if (itemCount < 1 || availableSubFilters.length < 2) return null

  return (
    <HorizontalContainerWithFadedOverflow>
      <StyledGroup gap={8} horizontalAlign="start" direction="horizontal">
        <FilterChip
          key="allItems"
          onClick={onClearFilters}
          size="medium"
          startIcon={<LineListUlIcon />}
          selected={selectedFiltersSet.size === 0}
        >
          All offers · {itemCount}
        </FilterChip>
        {availableSubFilters.map((filter: TripPlannerFilterTypeCount) => {
          const { filterType, count } = filter
          const Icon = FilterIcons[filterType]

          return (
            <FilterChip
              key={filterType}
              selected={selectedFiltersSet.has(filterType)}
              size="medium"
              startIcon={<Icon />}
              onClick={() => onToggleFilter(filterType)}
            >
              {FilterLabels[filterType]} · {count}
            </FilterChip>
          )
        })}
      </StyledGroup>
    </HorizontalContainerWithFadedOverflow>
  )
}

export default TripPlannerFilterChips
