import React from 'react'

import CruiseV1ItemCard from './CruiseV1ItemCard'
import CruiseV2ItemCard from './CruiseV2ItemCard'

import { CruiseItem } from 'tripPlanner/types/tripItem'
import { isCruiseV2, isCustomItem } from 'tripPlanner/utils'

interface Props {
  item: CruiseItem
  onClick?: () => void
  position?: number
}

export default function CruiseItemCard({ item, onClick, position }: Props) {
  // LE Cruise item V1 & V2
  if (!isCustomItem(item)) {
    if (isCruiseV2(item)) {
      return (
        <CruiseV2ItemCard item={item} onClick={onClick} position={position} />
      )
    } else {
      return (
        <CruiseV1ItemCard item={item} onClick={onClick} position={position} />
      )
    }
  }

  // Note: Booked cruises currently not savable, so not handled here

  return null
}
