import React, { useMemo } from 'react'
import Group from 'components/utils/Group'
import BodyText from 'components/Luxkit/Typography/BodyText'
import styled from 'styled-components'
import { rem } from 'polished'
import { mediaQueryUp } from 'components/utils/breakpoint'
import HeightFillingImage from 'components/Common/HeightFillingImage'
import config from 'constants/config'
import TextLink from 'components/Luxkit/TextLink'
import HeadingTextBlock from 'components/Luxkit/TextBlocks/HeadingTextBlock'
import LabelGroup from 'components/Luxkit/Label/LabelGroup'
import moment from 'moment'
import { getDaysToGoLabel } from './utils'
import StructureLabel from 'components/Luxkit/Label/StructureLabel'
import useOffer from 'hooks/Offers/useOffer'

const ImageRatioContainer = styled.div`
  height: ${rem(68)};
  width: ${rem(68)};

  ${mediaQueryUp.tablet} {
    width: ${rem(164)};
    height: ${rem(94)};
  }
`
interface Props {
  order: App.Order;
}

function OfferDetailUpsellSection(props: Props) {
  const { order } = props
  const offerId = order.items[0]?.offerId || order.bedbankItems[0]?.offer.id
  const [offer] = useOffer<App.Offer | App.BedbankOffer>(offerId)
  const checkInDate = order.items[0]?.reservation?.startDate || order.bedbankItems[0]?.checkIn
  const checkOutDate = order.items[0]?.reservation?.endDate || order.bedbankItems[0]?.checkOut
  const duration = order.items[0]?.duration || order.bedbankItems[0]?.duration

  const dateLabel = useMemo(() => {
    if (!checkInDate || !checkOutDate) return ''

    const formattedCheckInDate = moment(checkInDate)
    const formattedCheckOutDate = moment(checkOutDate)

    if (!formattedCheckInDate.isValid() || !formattedCheckOutDate.isValid()) return ''

    return `${formattedCheckInDate.format('D')} - ${formattedCheckOutDate.format('D MMM')}`
  }, [checkInDate, checkOutDate])

  const daysToGoLabel = getDaysToGoLabel(checkInDate, duration)

  if (!offer?.property?.name) return null

  return (<Group direction="horizontal" gap={16} tabletGap={24} horizontalAlign="space-between" tabletDirection="horizontal-reverse" tabletHorizontalAlign="start">
    <Group direction="vertical" gap={4} tabletGap={8}>
      {daysToGoLabel && <LabelGroup>
        <StructureLabel variant="promotion">{daysToGoLabel}</StructureLabel>
      </LabelGroup>}
      <HeadingTextBlock variant="heading2">{offer.property.name}</HeadingTextBlock>
      <BodyText variant="medium">
        {dateLabel} &middot;{' '}
        <TextLink
          to={config.agentHub.isEnabled ? undefined : `/account/my-escapes/order/${order.id}`}
          href={config.agentHub.isEnabled ? `${config.AGENT_PORTAL_URL}/bookings` : undefined}
          target={config.agentHub.isEnabled ? '_blank' : undefined}
          data-testid="manage-booking"
        >
          Manage booking
        </TextLink>
      </BodyText>
    </Group>
    {offer.image.id && (
      <ImageRatioContainer>
        <HeightFillingImage fit="center" id={offer.image.id}/>
      </ImageRatioContainer>
    )}
  </Group>
  )
}

export default OfferDetailUpsellSection
