import React, { useMemo } from 'react'
import FlightDeals from './FlightDeals'
import { rem } from 'polished'
import styled from 'styled-components'
import useFlightDeals from 'hooks/Flights/useFlightDeals'

const Container = styled.div`
  padding-top: ${rem(40)};
  padding-bottom: ${rem(40)};
`

function HomepageFlightDeals() {
  const [flightDeals, flightDealsLoading] = useFlightDeals()
  const heroDeals = useMemo(() => flightDeals.filter(deal => !!deal.heroImage?.id), [flightDeals])

  if (!flightDealsLoading && heroDeals.length === 0) {
    return null
  }

  return (
    <Container>
      <FlightDeals flightDeals={flightDeals} flightDealsLoading={flightDealsLoading} />
    </Container>
  )
}

export default React.memo(HomepageFlightDeals)
