import { fetchCruiseOffer } from 'actions/CruiseActions'
import useApiQuery from './useApiQuery'

export function useCruise(id?: string) {
  return useApiQuery({
    fieldName: 'cruise',
    getData: state => state.cruise.cruiseOffers[id!],
    getFetching: state => state.cruise.cruiseOffersLoading[id!],
    getError: state => state.cruise.cruiseOffersErrors[id!],
    getFetchAction: () => fetchCruiseOffer(id!),
    fetchDependencies: [id],
    enabled: !!id,
  })
}
