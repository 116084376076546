import React, { useCallback, useContext, useRef } from 'react'
import useToggle from 'hooks/useToggle'
import ListItem from 'components/Luxkit/List/ListItem'
import Clickable from 'components/Common/Clickable'
import LineAngleDownIcon from 'components/Luxkit/Icons/line/LineAngleDownIcon'
import HeadingTextBlock from 'components/Luxkit/TextBlocks/HeadingTextBlock'
import IconButton from 'components/Luxkit/Button/IconButton'
import DropdownList from 'components/Luxkit/Dropdown/List/DropdownList'
import ModalContext from 'contexts/ModalContext'
import { useIsMobileScreen } from 'hooks/useScreenSize'
import FlightDealAirportSelectModal from './FlightDealAirportSelectModal'

interface Props {
  selected: App.Airport;
  airports: Array<App.Airport>;
  className?: string;
  onChange: (location: App.Airport) => void;
}

function FlightDealAirportSelect(props: Props) {
  const triggerRef = useRef(null)
  const { selected, airports, className, onChange } = props
  const [dropdownOpen, toggleDropdown,, closeDropdown] = useToggle()

  const isMobile = useIsMobileScreen()
  const onSelectLocation = useCallback((e: React.MouseEvent<HTMLElement>) => {
    const location = airports.find(({ code }) => code === e.currentTarget.dataset.code)
    if (location) {
      onChange(location)
      closeDropdown()
    }
  }, [airports, onChange, closeDropdown])

  const showModal = useContext(ModalContext)

  const onSelectAirport = useCallback(async() => {
    if (isMobile) {
      const airport = await showModal<App.Airport | undefined>(<FlightDealAirportSelectModal
        airports={airports}
        selectedAirport={selected}
      />)

      if (airport) {
        onChange(airport)
      }
    } else {
      toggleDropdown()
    }
  }, [isMobile, showModal, airports, selected, onChange, toggleDropdown])

  return (
    <>
      <Clickable ref={triggerRef} onClick={onSelectAirport} className={className}>
        <HeadingTextBlock
          endIcon={
            <IconButton kind="tertiary" variant="dark" nonInteractable>
              <LineAngleDownIcon />
            </IconButton>
          }
          variant="heading2alt"
        >
          {selected.name}
        </HeadingTextBlock>
      </Clickable>
      <DropdownList
        size="M"
        open={dropdownOpen}
        triggerRef={triggerRef}
        anchorRef={triggerRef}
        onClose={closeDropdown}
      >
        {airports.map((airport) =>
          <ListItem
            key={airport.code}
            size="medium"
            title={airport.name}
            data-code={airport.code}
            onClick={onSelectLocation}
            selected={selected.code === airport.code}
          />,
        )}
      </DropdownList>
    </>
  )
}

export default FlightDealAirportSelect
