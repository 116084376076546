import { rem } from 'polished'
import React, { PropsWithChildren, useContext, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import styled from 'styled-components'

import SavedItemRemoveButton from '../ItemCard/Common/SavedItemRemoveButton'
import { ItemImageContainer } from './ItemImageContainer'

import BookedLabel from 'tripPlanner/components/BookedLabel'
import ClickableLink from 'components/Common/Clickable/ClickableLink'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import LineNotesIcon from 'components/Luxkit/Icons/line/LineNotesIcon'
import StatusLabel from 'components/Luxkit/Label/StatusLabel'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import BodyText from 'components/Luxkit/Typography/BodyText'
import { mediaQueryUp } from 'components/utils/breakpoint'
import Group from 'components/utils/Group'
import { EmptyArray, nonNullable } from 'lib/array/arrayUtils'
import noop from 'lib/function/noop'
import { memoWithNestedProps } from 'lib/memoize/memoWithNestedProps'
import TripContext from 'tripPlanner/contexts/TripContext'
import { ItemMetadata } from 'tripPlanner/models'
import { TripItem } from 'tripPlanner/types/tripItem'
import OfferRating from 'components/Common/NumberRating/OfferRating'
import { isOfferRatingDisplayable } from 'lib/order/reviewUtils'

const Card = styled(ClickableLink)`
  background-color: ${props => props.theme.palette.neutral.default.eight};
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  gap: ${rem(20)};
  min-height: ${rem(75)};
  break-inside: avoid-page;
  width: 75vw;

  ${mediaQueryUp.tablet} {
    width: ${rem(364)};
  }
`

const Content = styled.div`
  position: relative;
  flex: 1;
`

const Statuses = styled(Group)`
  flex: 1;
`

const NumberRatingHideBelow480 = styled(OfferRating)`
  @media (max-width: 480px) {
    display: none;
  }
`

interface Props {
  item: TripItem
  name: string
  imgId?: string
  imgSrc?: string
  descriptors?: Array<string | undefined>
  locationDescription?: React.ReactNode
  priceAreaContent?: React.ReactNode
  to?: string
  isSoldOut?: boolean
  testId?: string
  /** Disables un-bookmark button */
  nonInteractable?: boolean
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void
  onImpression?: () => void
  rating?: App.OfferRating
}

function MiniSavedOfferCard({
  item,
  name,
  imgId,
  imgSrc,
  locationDescription,
  priceAreaContent,
  to,
  isSoldOut,
  descriptors = EmptyArray,
  testId,
  nonInteractable = false,
  onClick,
  onImpression = noop,
  rating,
}: PropsWithChildren<Props>) {
  const { type } = item
  const Icon = ItemMetadata[type].icon

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  })

  useEffect(() => {
    if (inView) {
      onImpression()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView])

  const tripContext = useContext(TripContext)
  const canEdit = tripContext?.permissions.canEdit ?? false
  const descriptorsString = nonNullable(descriptors)
    .filter((text) => text.length > 0)
    .join(' · ')
  const isBooked = 'isBooked' in item && item.isBooked

  return (
    <Card
      to={to}
      target="_blank"
      data-testid={testId}
      onClick={onClick}
      ref={ref}
    >
      <ItemImageContainer imgId={imgId} imgSrc={imgSrc} />
      <Content>
        <Group
          direction="vertical"
          gap={8}
          verticalAlign="space-between"
          fullHeight
        >
          <VerticalSpacer gap={8}>
            <Group
              direction="horizontal"
              gap="4 8"
              verticalAlign="center"
              horizontalAlign="space-between"
              wrap="wrap"
            >
              <BodyTextBlock variant="small" startIcon={<Icon size="XS" />}>
                {ItemMetadata[type].singularName}
              </BodyTextBlock>
              <Statuses
                direction="horizontal"
                gap={8}
                verticalAlign="center"
                horizontalAlign="end"
              >
                {!isBooked && isSoldOut && (
                  <StatusLabel variant="cancelled">Sold Out</StatusLabel>
                )}
                {isBooked && <BookedLabel />}
                {!nonInteractable && canEdit && (
                  <SavedItemRemoveButton item={item} />
                )}
                {isOfferRatingDisplayable(rating) && <NumberRatingHideBelow480
                  rating={rating}
                  hideLink
                  variant="xs"
                  onlyNumber
                />}
              </Statuses>
            </Group>
            <VerticalSpacer gap={4}>
              {locationDescription}
              <BodyText variant="medium" weight="bold" lineClamp={2}>
                {name}
              </BodyText>
            </VerticalSpacer>
            {descriptorsString && (
              <BodyTextBlock
                variant="small"
                startIcon={<LineNotesIcon size="XS" />}
              >
                {descriptorsString}
              </BodyTextBlock>
            )}
          </VerticalSpacer>
          {priceAreaContent && (
            <Group direction="horizontal" horizontalAlign="start">
              {priceAreaContent}
            </Group>
          )}
        </Group>
      </Content>
    </Card>
  )
}

export default memoWithNestedProps(MiniSavedOfferCard, ['descriptors'])
