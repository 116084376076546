import React from 'react'
import { rem } from 'polished'
import styled from 'styled-components'
import { mediaQueryUp } from 'components/utils/breakpoint'
import Group from 'components/utils/Group'
import BodyText from 'components/Luxkit/Typography/BodyText'
import TextLink from 'components/Luxkit/TextLink'
import CardCarousel from 'components/Luxkit/Carousel/CardCarousel'

const TileContainer = styled(Group)`
  color: ${props => props.theme.palette.neutral.default.one};
  width: 70vw;

  ${mediaQueryUp.tablet} {
    width: ${rem(296)};
  }

  ${mediaQueryUp.desktop} {
    width: unset;
  }
`

export interface ValueSectionItem {
  key: string;
  heading?: string;
  body: string;
  icon: JSX.Element;
  link?: ValueSectionItemLink;
  show?: boolean;
}

interface ValueSectionItemLink {
    text: string;
    href?: string;
}

interface Props {
  values: Array<ValueSectionItem>;

}

function ValuePropositionItems(props: Props) {
  const { values } = props

  return (
    <CardCarousel pageSize={values.length}>
      {values.map(({ icon, heading, body, link, key }) => (
        <TileContainer
          key={key}
          direction="vertical"
          gap={12}
        >
          {icon}
          <Group direction="vertical" gap={12}>
            <Group direction="vertical" gap={4}>
              {heading && (
                <BodyText variant="medium" weight="bold">
                  {heading}
                </BodyText>
              )}
              <BodyText variant="medium">{body}</BodyText>
            </Group>
            {link?.text && (
              <TextLink href={link?.href} size="small" weight="bold" target="_blank">
                {link.text}
              </TextLink>
            )}
          </Group>
        </TileContainer>
      ))}
    </CardCarousel>
  )
}
export default ValuePropositionItems
