import React from 'react'
import styled from 'styled-components'

import StatusLabel from 'components/Luxkit/Label/StatusLabel'

const StatusLabelWithPrintBorder = styled(StatusLabel)`
  @media print {
    &.kind-tertiary {
      border: 1px solid ${props => props.theme.palette.neutral.default.five};
    }
  }
`

interface Props {
  className?: string
}

function BookedLabel({ className }: Props) {
  return (
    <StatusLabelWithPrintBorder variant="confirmation" className={className}>
      Booked
    </StatusLabelWithPrintBorder>
  )
}

export default BookedLabel
