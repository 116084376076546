import { max } from 'lib/array/arrayUtils'
import moment from 'moment'
import { BasicTrip, UserSettings } from 'tripPlanner/types/common'

export function getCanShowTripModule(userSettings: UserSettings | undefined): boolean {
  return !!userSettings && (
    !userSettings.homepageTripModuleDismissedAt ||
    moment(userSettings.homepageTripModuleDismissedAt).add(30, 'days').isBefore(moment())
  )
}

export function getTripToShowOnHomepage(trips: Array<BasicTrip> = []): BasicTrip | undefined {
  const candidateTrips = trips.filter(trip => trip.isActivelyPlanned)
  const candidateTrip = max(candidateTrips, trip => trip.interactedAt.unix())

  return candidateTrip
}
