import React from 'react'
import LoadingBox from 'components/Common/Loading/LoadingBox'
import { rem } from 'polished'
import styled from 'styled-components'

const LoaderContainer = styled(LoadingBox)`
  height: ${rem(280)};
`

interface Props {
  className?: string;
}

function FlightDealTileSkeletonLoader(props: Props) {
  return (
    <LoaderContainer className={props.className} />
  )
}

export default FlightDealTileSkeletonLoader
