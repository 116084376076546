import React, { useCallback, useState } from 'react'

import FullWidthCarousel from 'components/Luxkit/Carousel/FullWidthCarousel'
import CruiseItemCard from 'tripPlanner/components/MiniOfferItemCard/Cruise'
import ExperienceItemCard from 'tripPlanner/components/MiniOfferItemCard/ExperienceItemCard'
import LETourItemCard from 'tripPlanner/components/MiniOfferItemCard/Tour'
import { TripItem } from 'tripPlanner/types/tripItem'
import { getNonEmptyProductFilters, filterTripItems } from 'tripPlanner/utils/summary'
import LayoutContainer from 'components/Common/LayoutContainer'

import AddMoreCard from './AddMoreCard'
import TripItemFilterChips from './TripItemFilterChips'
import { homepageTripModuleBasicEvent, homepageTripModuleFilterClick, homepageTripModuleViewTripClick } from 'analytics/eventDefinitions'
import { fireInteractionEvent } from 'api/googleTagManager'
import AccommodationItemCard from 'tripPlanner/components/MiniOfferItemCard/AccommodationItemCard'

interface Props {
  tripId: string
  tripName: string
  items: Array<TripItem>
}

const leOfferSourceTypes = new Set<TripItem['sourceType']>([
  'LE_Hotel',
  'LE_Hotel_Bedbank',
  'LE_Tour',
  'LE_Cruise',
  'LE_Experience',
  'LE_Accommodation_Rental',
])

function TripOfferCarousel({ tripId, tripName, items }: Props) {
  const leOfferItems = items.filter(item => leOfferSourceTypes.has(item.sourceType))

  // Currently can only select one filter at a time, but Trip Planner's filtering logic is written to support multiple filters.
  // So we use an array to store the selected filters, but it contains at most one value.
  const [selectedFilters, setSelectedFilters] = useState<Array<App.TripPlannerSubFilterType>>([])

  const onToggleFilter = useCallback((filter: App.TripPlannerSubFilterType) => {
    const newFilters = selectedFilters.includes(filter) ? [] : [filter]
    setSelectedFilters(newFilters)
    fireInteractionEvent(homepageTripModuleFilterClick(newFilters))
  }, [selectedFilters])

  const onClearFilters = useCallback(() => {
    setSelectedFilters([])
    fireInteractionEvent(homepageTripModuleFilterClick([]))
  }, [])

  const availableFilters = getNonEmptyProductFilters(leOfferItems)

  const filteredItems = filterTripItems(leOfferItems, selectedFilters)

  const onClickOfferCard = useCallback(() => {
    fireInteractionEvent(homepageTripModuleBasicEvent('tile_click'))
  }, [])

  const onClickAddMoreCard = useCallback(() => {
    fireInteractionEvent(homepageTripModuleViewTripClick('secondary'))
  }, [])

  return (
    <>
      {availableFilters.length > 0 && <LayoutContainer>
        <TripItemFilterChips
          selectedFilters={selectedFilters}
          availableSubFilters={availableFilters}
          itemCount={leOfferItems.length}
          onToggleFilter={onToggleFilter}
          onClearFilters={onClearFilters}
        />
      </LayoutContainer>}

      <FullWidthCarousel itemsPerArrow={2} snap="start" key={`${tripId}:${selectedFilters.join(',')}`}>
        {filteredItems.map((item, index) => {
          if (item.type === 'TOUR') {
            return <LETourItemCard item={item} key={item.id} onClick={onClickOfferCard} position={index} />
          }
          if (item.type === 'CRUISE') {
            return <CruiseItemCard item={item} key={item.id} onClick={onClickOfferCard} position={index} />
          }
          if (item.type === 'EXPERIENCE') {
            return <ExperienceItemCard item={item} key={item.id} onClick={onClickOfferCard} position={index} />
          }
          if (item.type === 'ACCOMMODATION') {
            return <AccommodationItemCard item={item} key={item.id} onClick={onClickOfferCard} position={index} />
          }
          return null
        })}
        <AddMoreCard tripId={tripId} tripName={tripName} onClick={onClickAddMoreCard} />
      </FullWidthCarousel>
    </>
  )
}

export default TripOfferCarousel
