import React from 'react'
import Carousel from 'nuka-carousel'
import Leaderboard from 'components/Common/Banner/Leaderboard'
import { EmptyArray } from 'lib/array/arrayUtils'

interface Props {
  banners: Array<App.ListBanner>
  onBannerClick?: (banner: App.ListBanner) => void
  onBannerLoad?: (banner: App.ListBanner) => void
  interval?: number
  enableControls?: boolean
  prevButtonStyle?: React.CSSProperties
  nextButtonStyle?: React.CSSProperties
  pagingDotsStyle?: React.CSSProperties
  onClickOpenNewTab?: boolean
}

function BannerCarousel({
  banners = EmptyArray,
  onBannerClick,
  onBannerLoad,
  interval,
  enableControls,
  prevButtonStyle,
  nextButtonStyle,
  pagingDotsStyle,
  onClickOpenNewTab,
}: Props) {
  if (banners.length > 1) {
    return <Carousel
      autoplay
      wrapAround
      autoplayInterval={(interval || 5) * 1000}
      withoutControls={!enableControls}
      defaultControlsConfig={{
        ...(prevButtonStyle ? { prevButtonStyle } : {}),
        ...(nextButtonStyle ? { nextButtonStyle } : {}),
        ...(pagingDotsStyle ? { pagingDotsStyle } : {}),
      }}
    >
      {banners.map(banner => <Leaderboard
        key={banner.bannerId}
        banners={[banner]}
        onBannerClick={onBannerClick}
        onBannerLoad={onBannerLoad}
        onClickOpenNewTab={onClickOpenNewTab}
      />)}
    </Carousel>
  }

  if (banners.length === 1) {
    return <Leaderboard
      banners={banners}
      onBannerClick={onBannerClick}
      onClickOpenNewTab={onClickOpenNewTab}
    />
  }

  return null
}

export default BannerCarousel
