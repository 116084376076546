import React, { useCallback, useContext, useMemo } from 'react'

import LoadingItemCard from './LoadingItemCard'
import { PlannedItemPriceContent } from '../ItemCard/PlannedItemPriceContent'
import MiniSavedOfferCard from './MiniSavedOfferCard'

import OfferListEventsContext, {
  OfferListEvents,
} from 'components/OfferList/OfferListEventsContext'
import GeoContext from 'contexts/geoContext'
import { EventDataKey } from 'home/pages/HomePage/useHomepageAnalytics'
import { useRegionTimeFormat } from 'hooks/useRegionTimeFormat'
import noop from 'lib/function/noop'
import { getExperienceOfferPageURL } from 'lib/offer/offerPageURL'
import BookmarkLocation from 'tripPlanner/components/Bookmark/BookmarkCardNew/BookmarkLocation'
import { useExperience } from 'tripPlanner/hooks/api'
import { ExperienceItem } from 'tripPlanner/types/tripItem'
import {
  buildMyEscapesOrderLink,
  formatDateTimeOrDateRange,
  isLeBookmark,
} from 'tripPlanner/utils'
import { buildProductLink } from 'tripPlanner/utils/bookmark/restoreCart'
import { isExperienceAvailable } from 'tripPlanner/utils/experience'

export type ExperienceItemCardProps = {
  item: ExperienceItem
  onClick?: () => void
  position?: number
}

export function ExperienceItemCard({
  item,
  onClick = noop,
  position,
}: ExperienceItemCardProps) {
  const timeFormat = useRegionTimeFormat()

  const { currentCurrency, currentRegionCode } = useContext(GeoContext)

  let experienceId: string | undefined
  if (item.code) {
    experienceId = item.code
  }
  const { data: experience, isLoading } = useExperience({
    currentCurrency,
    currentRegionCode,
    experienceId,
  })

  const isOfferAvailable = experience ? isExperienceAvailable(experience) : true

  const priceAreaContent = useMemo(() => {
    if (isLeBookmark(item)) {
      const { price, discountPercent, currencyCode } = item.savedItemData
      return (
        <PlannedItemPriceContent
          discountPercent={discountPercent}
          price={price}
          currencyCode={currencyCode}
          isOfferAvailable={isOfferAvailable}
          discountDisplayType="inlineBadge"
        />
      )
    }
    return null
  }, [isOfferAvailable, item])

  const locationDescription = useMemo(() => {
    if (experience) {
      return (
        <BookmarkLocation
          variant="with_experience"
          startLocation={experience.location.name}
          typeOfExperience={experience.primaryCategory?.name}
          showIcon={false}
        />
      )
    }
    return null
  }, [experience])

  const dispatchOfferListEvent = useContext(OfferListEventsContext)

  const onImpression = useCallback(() => {
    if (experience && position !== undefined) {
      dispatchOfferListEvent({
        type: OfferListEvents.productImpression,
        offer: experience,
        position,
        key: EventDataKey.ExperienceImpression,
      })
    }
  }, [dispatchOfferListEvent, experience, position])

  const onClickCard = useCallback(() => {
    onClick()
    if (experience && position !== undefined) {
      dispatchOfferListEvent({
        type: OfferListEvents.productClick,
        offer: experience,
        position,
        key: EventDataKey.ExperienceClick,
      })
    }
  }, [onClick, dispatchOfferListEvent, experience, position])

  if (isLoading) {
    return (
      <LoadingItemCard
        itemType={item.type}
        includeDescriptors={!!item.startDate}
      />
    )
  }

  if (!experience) {
    return null
  }

  const timeDescription = formatDateTimeOrDateRange(item, timeFormat)

  const imgId = experience.images[0]?.id
  const imgSrc = experience.images[0]?.url

  let tileLink: string | undefined
  if (
    item.sourceType === 'LE_Experience' &&
    item.isBooked &&
    item.confirmationCode
  ) {
    tileLink = buildMyEscapesOrderLink(item)
  } else if (isLeBookmark(item)) {
    tileLink = buildProductLink(item, true, { experience })
  } else {
    tileLink = getExperienceOfferPageURL(experience)
  }

  return (
    <MiniSavedOfferCard
      to={tileLink}
      onClick={onClickCard}
      onImpression={onImpression}
      item={item}
      name={item.name}
      imgId={imgId}
      imgSrc={imgSrc}
      locationDescription={locationDescription}
      descriptors={[timeDescription]}
      priceAreaContent={priceAreaContent}
      isSoldOut={!isOfferAvailable}
    />
  )
}

export default ExperienceItemCard
