import ListItem from 'components/Luxkit/List/ListItem'
import Modal from 'components/Luxkit/Modal/Modal'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import React from 'react'

interface Props {
  airports: Array<App.Airport>;
  selectedAirport?: App.Airport;
}

function FlightDealAirportSelectModal(props: Props) {
  const { airports, selectedAirport } = props

  const context = useModalElementContext<App.Airport>()

  return <Modal title="Departing from">
    {airports.map((airport) =>
      <ListItem
        key={airport.code}
        size="medium"
        selected={selectedAirport?.code === airport.code}
        title={airport.name}
        data-code={airport.code}
        onClick={() => context.resolve(airport)}
      />,
    )}
  </Modal>
}

export default FlightDealAirportSelectModal
