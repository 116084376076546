import React from 'react'

import TourV1ItemCard from './TourV1ItemCard'
import TourV2ItemCard from './TourV2ItemCard'
import LoadingItemCard from '../LoadingItemCard'

import { isTourV1Offer, isTourV2Offer } from 'lib/offer/offerTypes'
import useOffer from 'hooks/Offers/useOffer'
import { TourItem } from 'tripPlanner/types/tripItem'

interface Props {
  item: TourItem
  onClick?: () => void
  position?: number
}

export default function LETourItemCard({ item, onClick, position }: Props) {
  const [offer, isLoading] = useOffer(item.code)
  if (isLoading) return <LoadingItemCard itemType={item.type} />
  if (!offer) {
    return null
  } else if (isTourV1Offer(offer)) {
    return (
      <TourV1ItemCard
        item={item}
        offer={offer}
        onClick={onClick}
        position={position}
      />
    )
  } else if (isTourV2Offer(offer)) {
    return (
      <TourV2ItemCard
        item={item}
        offer={offer}
        onClick={onClick}
        position={position}
      />
    )
  }

  return null
}
