import { useContext, useMemo } from 'react'

import GeoContext from 'contexts/geoContext'
import { sortBy, uniqueBy } from 'lib/array/arrayUtils'
import useFlightDeals from 'hooks/Flights/useFlightDeals'
import findClosestAirport from 'lib/flights/findClosestAirport'
import { useAppSelector } from 'hooks/reduxHooks'
import { getAirportsByCode, getDefaultAirport } from 'selectors/flightsSelectors'

type FlightLocationDetails = [
  locations: Array<App.Airport>,
  defaultLocation: App.Airport | undefined
]

function useFlightDealLocations(category?: string): FlightLocationDetails {
  const [flightDeals] = useFlightDeals(category)
  const { latitude, longitude } = useContext(GeoContext)
  const airportsByCode = useAppSelector(getAirportsByCode)
  const defaultAirport = useAppSelector(getDefaultAirport)

  const locations = useMemo(() => {
    const uniqueLocations = uniqueBy(flightDeals, deal => deal.originAirportCode)
      .map((deal): App.Airport => ({
        ...airportsByCode[deal.originAirportCode],
        // Deal names are actually the 'city' name - which is how flight deals show their locations
        // so override the airport name with ours
        name: deal.originName,
      }))

    return sortBy(uniqueLocations, airport => airport.name, 'asc')
  }, [airportsByCode, flightDeals])

  const defaultLocation = useMemo<App.Airport | undefined>(() => {
    // try use the lat/long provided the users current location, or if that doesn't exist - their default airport
    // to try find the closest flight deal location to them
    const lat = latitude ?? defaultAirport?.latitude
    const long = longitude ?? defaultAirport?.longitude

    return findClosestAirport(locations, lat, long)
  },
  [latitude, longitude, locations, defaultAirport])

  return [locations, defaultLocation ?? locations[0]]
}

export default useFlightDealLocations
