import { AnalyticEvent, AnalyticEventPlacement } from 'actions/AnalyticsActions'
import { arrayToObject } from 'lib/array/arrayUtils'

function promotionMap(bannerId: string, placements: Record<string, AnalyticEventPlacement>) {
  const details = placements[bannerId]
  if (details) {
    return {
      id: details.bannerId,
      name: details.name,
      creative: details.creative,
      position: details.position,
    }
  }
}

export function promotionBanner(data: AnalyticEvent) {
  const { bannerId, placements, hitType } = data
  if (bannerId) {
    const p = placements ? arrayToObject(placements, p => p.bannerId) : {}
    return {
      hitType,
      ecommerce: {
        promoView: {
          promotions: [
            promotionMap(bannerId, p),
          ],
        },
      },
    }
  }
}
