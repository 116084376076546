import Heading from 'components/Luxkit/Typography/Heading'
import Group from 'components/utils/Group'
import React, { useCallback, useContext, useMemo } from 'react'
import { getFrontPageUSPContent } from './constants'
import TextButton from 'components/Luxkit/Button/TextButton'
import * as Analytics from 'analytics/analytics'
import ValuePropositionItems from 'home/components/ValuePropositionBanner/ValuePropositionItems'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import GeoContext from 'contexts/geoContext'
import ModalContext from 'contexts/ModalContext'
import AccountAccessModal from 'components/Account/AccountAccess/AccountAccessModal'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import useOptimizelyExperiment from 'hooks/Optimizely/useOptimizelyExperiment'
import { OptimizelyExperiments } from 'constants/optimizely'
import getStaticPagesByType from 'lib/utils/getStaticPagesByType'

function FrontPageUSPComponent() {
  const { currentRegionCode } = useContext(GeoContext)
  const uspItems = useMemo(() => getFrontPageUSPContent(currentRegionCode).filter(item => item.show), [currentRegionCode])
  const showModal = useContext(ModalContext)

  const signUpModalTitleEnabled = useOptimizelyExperiment(OptimizelyExperiments.signUpModalTitleEnabled)
  const titleExperimentVariant = signUpModalTitleEnabled ? <>Exclusive prices and <i>more</i> with every booking</> : <>Huge savings and <i>more</i> with every booking</>
  const isAboutUsPageAvailable = useMemo<boolean>(() => !!getStaticPagesByType(['aboutUs']).length, [])

  const onLearnMoreClick = useCallback(() => {
    Analytics.trackClientEvent({
      subject: 'learn_about_us',
      action: 'clicked',
      category: 'usp_about_us',
      type: 'interaction',
    })
  }, [])

  const onSignupClick = useCallback(() => {
    showModal(<AccountAccessModal />)
  }, [showModal])

  return <VerticalSpacer gap={32} >
    <Heading variant="heading2">
      {titleExperimentVariant}
    </Heading>
    <CSSBreakpoint max="mobile">
      <Group gap={8} direction="vertical">
        {uspItems.map(({ icon, body, key }) => (
          <BodyTextBlock
            key={key}
            variant="medium"
            colour="neutral-one"
            startIcon={<icon.type {...icon.props} size="S" />}
          >
            {body}
          </BodyTextBlock>
        ))}
      </Group>
    </CSSBreakpoint>
    <CSSBreakpoint min="tablet">
      <ValuePropositionItems values={uspItems} />
    </CSSBreakpoint>
    <Group
      gap={8}
      direction="vertical-reverse"
      tabletDirection="horizontal"
      tabletHorizontalAlign="end"
    >
      {isAboutUsPageAvailable &&
        <TextButton kind="tertiary" onClick={onLearnMoreClick} to="/about-us" target="_blank">
          Learn more about us
        </TextButton>
      }
      <TextButton kind="primary" onClick={onSignupClick}>
        Sign up and save
      </TextButton>
    </Group>
  </VerticalSpacer>
}

export default React.memo(FrontPageUSPComponent)
