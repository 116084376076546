import { rem } from 'polished'
import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'

import ClickableLink from 'components/Common/Clickable/ClickableLink'
import LoadingBox from 'components/Common/Loading/LoadingBox'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import LineMapMarkerIcon from 'components/Luxkit/Icons/line/LineMapMarkerIcon'
import LineNotesIcon from 'components/Luxkit/Icons/line/LineNotesIcon'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import TextLoadingBox from 'components/Luxkit/Typography/TextLoadingBox'
import { mediaQueryUp } from 'components/utils/breakpoint'
import Group from 'components/utils/Group'
import { ItemMetadata } from 'tripPlanner/models'
import { TripItem } from 'tripPlanner/types/tripItem'

const Card = styled(ClickableLink)`
  background-color: ${props => props.theme.palette.neutral.default.eight};
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  gap: ${rem(20)};
  min-height: ${rem(75)};
  break-inside: avoid-page;
  width: 75vw;
  flex-shrink: 0;

  ${mediaQueryUp.tablet} {
    width: ${rem(364)};
  }
`

const ImageContainer = styled.div`
  width: ${rem(100)};
  height: ${rem(100)};
  flex-shrink: 0;
  border-radius: ${(props) => props.theme.borderRadius.S};
  overflow: hidden;
`

const ImageLoader = styled(LoadingBox)`
  height: 100%;
`

const Content = styled.div`
  position: relative;
  flex: 1;
`

const FullHeightGroup = styled(Group)`
  height: 100%;
`

const Width60 = styled.div`
  width: ${rem(60)};
`

const Width180 = styled.div`
  width: ${rem(180)};
  flex: 1;
`

interface Props {
  itemType?: TripItem['type']
  includeDescriptors?: boolean
}

function LoadingItemCard({
  itemType,
  includeDescriptors,
}: PropsWithChildren<Props>) {
  const Icon = itemType ? ItemMetadata[itemType].icon : LineMapMarkerIcon

  return (
    <Card>
      <ImageContainer>
        <ImageLoader />
      </ImageContainer>
      <Content>
        <FullHeightGroup
          direction="vertical"
          gap={8}
          verticalAlign="space-between"
        >
          <VerticalSpacer gap={8}>
            <Group
              direction="horizontal"
              gap={8}
              verticalAlign="center"
              horizontalAlign="space-between"
            >
              <BodyTextBlock variant="small" startIcon={<Icon size="XS" />}>
                {itemType && ItemMetadata[itemType].singularName}
                {!itemType && (
                  <Width60>
                    <TextLoadingBox typography="body-small" width="10ch" />
                  </Width60>
                )}
              </BodyTextBlock>
              <Width60>
                <TextLoadingBox typography="heading3" width="100%" />
              </Width60>
            </Group>
            <VerticalSpacer gap={4}>
              <TextLoadingBox typography="caption-medium" width="15ch" />
              <TextLoadingBox typography="body-medium" width="15ch" />
            </VerticalSpacer>
            {includeDescriptors && (
              <BodyTextBlock
                variant="small"
                startIcon={<LineNotesIcon size="XS" />}
              >
                <Width180>
                  <TextLoadingBox width="100%" />
                </Width180>
              </BodyTextBlock>
            )}
          </VerticalSpacer>
          <div>
            <TextLoadingBox typography="body-small" width="4ch" />
            <TextLoadingBox typography="heading5" width="8ch" />
          </div>
        </FullHeightGroup>
      </Content>
    </Card>
  )
}

export default LoadingItemCard
